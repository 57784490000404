import React from 'react';
import { NavLink } from 'react-router-dom';

function ResellerNavbar() {

  return (
    <div>
      <nav className="custom-navbar navbar navbar-expand-md navbar-dark" aria-label="Furni navigation bar">
        <div className="container">
        <NavLink className="navbar-brand" to="/">
        <img src="/assests/image/new_logo_qr.png" alt="Brand Logo" loading="lazy" />
      </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarsFurni" aria-controls="navbarsFurni" aria-expanded="false"
            aria-label="Toggle navigation" >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarsFurni">
            <ul className="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
              <li>
                <NavLink className="nav-link" to={{ pathname: "/myorder" }}>My Order</NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to={{ pathname: "/neworder" }}>New Order</NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to={{ pathname: "/myqrcode" }}>My QRcodes</NavLink>
              </li>
             
              <li>
                <NavLink className="nav-link" to={{ pathname: "/myaccount" }}>My Account</NavLink>
              </li> <li>
                <NavLink className="nav-link" to={{ pathname: "/paymentlist" }}>Payment List</NavLink>
              </li>
            </ul>
            <ul className="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
              <li className="nav-item" style={{ marginRight: 50 }}>
                <NavLink className="nav-link active" to="/resellerlogin" style={{ color: '#000000' }}>
                  <i className="fas fa-sign-out-alt" style={{ color: '#000000' }} /> Logout
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default ResellerNavbar;
