import React from 'react'
import { useLocation,NavLink } from 'react-router-dom';

function ThankuFinal() {

  const location = useLocation();
  const message = location.state?.message || "Your Message is sent successfully ..Thank you for Helping !!";
  return (
    <div>
        {/* <h2 className="logo_heading">QR Genie</h2> */}
        <div className="text-center mt-5 mb-4">
            <img src="assests/image/Thankufinal.svg" alt="Company Picture" className="company-pic" loading="lazy" />
            <p>{message}</p>  
            <NavLink to="/">
                  <button className="Login_btn">Home</button>
                </NavLink>
          </div>
    </div>
  )
}

export default ThankuFinal